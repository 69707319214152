import React, { useEffect, useState } from "react";
import { Link, Outlet } from "react-router-dom";
import { FaClock, FaRegUser, FaTasks } from "react-icons/fa";
import { IoExit, IoNotifications, IoSettings } from "react-icons/io5";
import { SiOpenproject } from "react-icons/si";
import { GrProjects } from "react-icons/gr";
import { HiOutlineDocumentReport } from "react-icons/hi";
import axios from "axios";
import { useAuth } from "./AuthContext/AuthContext";

function UserDashboard() {
const [count,setCount]=useState([])

    useEffect(() => {
        getNotifications();
    }, [])
  const { user,logout } = useAuth();
  


    const getNotifications = async() => {
        const response = await axios.get("http://97.107.130.242:14323/api/notification/notifications/66dad25f146f62ad9431fe37/unread");
        setCount(response?.data)
    }

  return (
   <div className="w-full h-screen overflow-y-hidden  scrollbar-hide">
      <div className="flex ">
        <div className=" w-[6rem] h-[100vh] flex bg-slate-900  shadow-4xl  flex-col">
          <p className="self-center text-lg font-extrabold pb-10 text-white mt-10 capitalize">
            User
          </p>
          <ul className="flex flex-col text gap-2 text-white justify-center items-center">
            <Link
              className="text-xl cursor-pointer hover:bg-blue-800 hover:rounded-full p-3 w-[3rem] h-[3rem] items-center justify-center flex rounded-md"
              to={"/user/user-timesheet"}
            >
              <FaRegUser />
            </Link>
            <Link
              className=" text-xl cursor-pointer hover:bg-blue-800 hover:rounded-full p-3 w-[3rem] h-[3rem] items-center justify-center flex rounded-md"
              to={"/user/user-timesheet/review"}
            >
              <GrProjects />
                      </Link>
             
            <Link
              className=" text-3xl cursor-pointer hover:bg-blue-800 hover:rounded-full p-3 w-[3rem] h-[3rem] items-center justify-center flex rounded-md"
              to={"/user/reports"}
            >
              <HiOutlineDocumentReport />
            </Link>
            

            <Link
              className="relative text-xl cursor-pointer hover:bg-blue-800 hover:rounded-full p-3 w-[3rem] h-[3rem] items-center justify-center flex rounded-md"
              to={"/user/notification"}
            >
                          <IoNotifications />
                          <p className="absolute w-[1.25rem] text-xs flex justify-center items-center top-0 right-0 h-[1.25rem] bg-red-500 rounded-full">{count?.length }</p>
                      </Link>
                      

            <Link
              className="text-xl cursor-pointer hover:bg-blue-800 hover:rounded-full p-3 w-[3rem] h-[3rem] items-center justify-center flex rounded-md"
              to={"/user/settings"}
            >
              <IoSettings />
            </Link>
            <Link
              onClick={() => {
                logout()
                localStorage.clear()
              }}
              className="text-xl cursor-pointer hover:bg-blue-800 hover:rounded-full p-3 w-[3rem] h-[3rem] items-center justify-center flex rounded-md"
              to={"/logout"}
            >
              <IoExit />
            </Link>
          </ul>
        </div>
        <div className="w-full h-[90vh]  m-12 rounded-lg overflow-y-scroll">
          <Outlet />
        </div>
      </div>
    </div>
  )
}

export default UserDashboard