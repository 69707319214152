import React, { useEffect, useState } from "react";
import axios from "axios";

// Interface for the Project response

const ProjectList = ({ projects }) => {
  return (
    <div className="max-w-5xl mx-auto p-8">
      <h1 className="text-2xl font-semibold mb-6">Project List</h1>

      <div className="grid grid-cols-1 gap-6">
        {projects.length > 0 ? (
          projects.map((project) => (
            <div
              key={project._id}
              className="border border-gray-300 rounded-md shadow p-4"
            >
              <h2 className="text-xl font-semibold">{project.projectName}</h2>
              <p className="text-sm text-gray-500">Type: {project.type}</p>
              <p className="text-sm text-gray-500">
                Start Date:{" "}
                {new Date(project.startDate).toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                })}
              </p>
              <p className="text-sm text-gray-500">Status: {project.status}</p>
            </div>
          ))
        ) : (
          <p className="text-sm text-gray-500">No projects available</p>
        )}
      </div>
    </div>
  );
};

export default ProjectList;
