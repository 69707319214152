import React, { useEffect, useState } from "react";
import CreateUserForm from "./UserSection/CreateUserComponent";
import UserList from "./UserSection/UserList";
import { IoAdd } from "react-icons/io5";
import axios from "axios";

function UsersComponent() {
  const [showForm, setShowForm] = useState(false);
  const [users, setUsers] = useState([]);

  const fetchUsers = async () => {
    try {
      const response = await axios.get(
        "http://97.107.130.242:14323/api/user/getUsers"
      ); // Replace with your actual API endpoint
      setUsers(response.data);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <div className="overfloy-y-scroll h-[100vh] ">
      <UserList users={users} />
      <button
        onClick={() => setShowForm(true)}
        className="text-white font-semibold w-[3rem] h-[3rem] absolute right-20 bottom-20 bg-red-500 rounded-2xl flex justify-center items-center text-2xl"
      >
        <IoAdd />
      </button>
      {showForm && (
        <div
          className="fixed w-full h-screen bg-gray-800/80  left-0 right-0 top-0 flex justify-center items-center shadow-inner 
        bottom-0"
        >
          <div className="bg-white w-1/2 rounded-xl p-8">
            <CreateUserForm handleClose={setShowForm} refetch={fetchUsers} />
          </div>
        </div>
      )}
    </div>
  );
}

export default UsersComponent;
