import React, { useEffect, useState } from "react";
import axios from "axios";
import { IoClose, IoTrash, IoTrashBin } from "react-icons/io5";

const UserList = ({ users }) => {
    // Fetch users from backend API
    const [projectList,setProjectList]=useState([])
    const [error, setError] = useState(false)
    const [selectedProject,setSelectedProject]=useState(null)
    
const unlinkProjectFromUser = async (userId, projectId) => {
  try {
    const response = await axios.post(
      `http://97.107.130.242:14323/api/user/unlinkProjectFromUser/${userId}/removeProject`,
      { projectId }
    );
    console.log('Association removed successfully:', response.data);
    alert('Association removed successfully:')
    return response.data;
  } catch (error) {
    console.error('Error removing association:', error.response ? error.response.data : error.message);
    throw error; // Re-throw the error to handle it in the calling function if needed
  }
};

   const linkProject = async (projectId) => {
    try {
      const response = await axios.post(
        `http://97.107.130.242:14323/api/user/linkProjectToUser/${showUserDetails?._id}/addProject`,
        {
          projectId: projectId,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
        if (response.status === 200) {
          setShowUserDetails(null)
        }

      // Handle success
      alert("Project successfully linked to the user!");
      console.log("Response:", response.data);
    } catch (error) {
      // Handle error
      alert("Failed to link the project to the user.");
        console.error("Error:", error.response ? error.response.data : error);
        setShowUserDetails(null)
    }
  };
    const generateColor = (index) => {
  const hue = (index * 137.508) % 360; // Using a large prime number for hue variance
  return `hsl(${hue}, 70%, 80%)`; // Adjust saturation and lightness for readability
};

    const projectLookup = async (query) => {
        console.log("Query", query)
        setSelectedProject(query)
    
       try {
      const response = await axios.get(
        `http://97.107.130.242:14323/api/project/search?query=${query}`
      );
      setProjectList(response.data);
      setError("");
       } catch (err) {
        setProjectList([]);
           
      if (err.response && err.response.status === 404) {
        setError("No matching projects found.");
        setProjectList([]);
      } else {
        setError("Error searching projects.");
      }
    }
    }
    const [showUserDetails, setShowUserDetails] = useState(null)
  return (
    <div className="max-w-5xl mx-auto p-8 overflow-y-auto">
      <h1 className="text-2xl font-semibold mb-6">User List</h1>
      {users.length === 0 && <p>Loading...</p>}
          <div className="grid grid-cols-1 gap-6">
              
        {users.map((user) => (
            <div
                onClick={() => {
                    setShowUserDetails(user)
                    console.log("Cicked Item",user)
                }}
            key={user._id}
            className="border border-gray-300 rounded-md shadow p-4 hover:bg-gray-100 cursor-pointer"
            >
                <div className="flex justify-between">
                    <h2 className="text-xl font-semibold">{user.name}</h2>
                    <button className="bg-green-800 p-2 text-sm text-white rounded-lg">Assign Project</button>
                
                </div>
            <p className="text-sm text-gray-500">{user.email}</p>
            <p className="text-sm text-gray-500">Role: {user.role}</p>
            <p className="text-sm text-gray-500">
              Employee ID: {user.employeeId}
            </p>
            <p className="text-sm text-gray-500">
              Employment Status: {user.employmentStatus}
            </p>
            <p className="text-sm text-gray-500">
              Active: {user.isActive ? "Yes" : "No"}
            </p>

            {/* Projects */}
            {user.projects.length > 0 ? (
              <div className="mt-4 ">
                        <div className="flex justify-between">
                            <h3 className="text-lg font-semibold">Projects:</h3>
                        </div>
                <ul className="list-disc list-inside">
                  {user.projects.map((project) => (
                    <li key={project._id}>
                      <strong>{project.projectName}</strong> - {project.type} (
                      {project.status})
                    </li>
                  ))}
                </ul>
              </div>
            ) : (
              <p className="mt-4 text-sm text-gray-500">No projects assigned</p>
            )}
          </div>
        ))}
          </div>
          
          {
              showUserDetails && <div className="fixed left-0 right-0 top-0 bottom-0 flex justify-center items-center bg-gray-800/80 z-10">
                  
                  <div className="w-1/2 h-2/3 rounded-xl p-8 bg-white shadow-xl">
                      
                      <div className="justify-between flex">    <p className="font-bold text-[#333333] text-2xl mb-4">Manage User</p> <IoClose onClick={() => setShowUserDetails(null)} className="text-xl cursor-pointer hover:bg-gray-200  rounded-full" />
                          
                         
                </div>
                      <div className="flex justify-between" >
                           <p><span className="font-bold text-[#333333]">Name:</span>{showUserDetails?.name}</p>
                          <p><span className="font-bold text-[#333333]">Email:</span> {showUserDetails?.email}</p>
                          {/* <p>Email: {showUserDetails?.email}</p> */}
                          
                     </div>
                      <div className="flex justify-between">
                      <p><span className="font-bold text-[#333333]">Employee Id:</span> {showUserDetails?.employeeId}</p>
                          
                          <p><span className="font-bold text-[#333333]">Employement Type:</span>{showUserDetails?.employmentStatus}</p>
                      <p><span className="font-bold text-[#333333]">Employement Status:</span> {showUserDetails?.isActive ?"Active":"Inactive"}</p>
                      </div>
                      <div className="h-[.5px] bg-gray-400 mt-3"></div>

                      <div className="my-5">
                          <p className="font-bold text-[#333333]">Current Projects </p>
                          
                         
                              
                          <div className="flex mt-2">
  {showUserDetails?.projects?.map((project, index) => (
      <div  
          style={{ backgroundColor: generateColor(index) }}
           className=" items-center justify-center flex gap-4  text-center rounded-2xl p-1 px-3 mx-2 text-sm"
      >
           <p
      key={project._id}
    
     
    >
      {project.projectName}
          </p>
          <button type="button" onClick={()=>unlinkProjectFromUser(showUserDetails?._id,project._id)}><IoClose className="text-2xl p-1 text-blue-700 hover:bg-gray-100 rounded-full"/></button>
   </div>
  ))}
</div>
                   </div>

                      <div className="flex flex-col my-2 gap-1"><label className="font-bold text-[#333333]">Assign Project</label>
                          <input type="text"  placeholder="Enter project name" value={selectedProject?.projectName} className="p-2 border rounded-lg" onChange={(e)=>projectLookup(e.target.value)} /></div>
                    {projectList.length>0 &&  <div className="w-full shadow-2xl bg-gray-100 rounded border mb-10 ">{projectList.map((project) => {
                          return <p onClick={() => {
                              setSelectedProject(project)
                              setProjectList([])
                            //   linkProject(project._id)
                          }} className="p-2 cursor-pointer" key={project._id} >{project.projectName }</p>
                      }) }</div>}
                      <button className="bg-orange-500 px-4 py-2 text-white rounded-xl"  onClick={()=>linkProject(selectedProject?._id)}>Add Project</button>
                  </div>
            </div>
         }
    </div>
  );
};

export default UserList;
