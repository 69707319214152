import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import AdminDashboard from '../../components/AdminDashboard';
import UsersComponent from '../../components/UsersComponent';
import AdminSettings from '../../components/AdminSettings';
import ProjectPage from '../../components/ProjectPage.jsx';
import FlaggedTimesheetComponent from '../../components/FlaggedSheet/FlaggedSheet';
import TaskPage from '../../components/TaskPage';
import AdminReport from '../../components/AdminReport/AdminReport';
import TimesheetPage from '../TimesheetPage';

const AdminRouter = () => {
  return (
    <Routes>
        <Route index element={<Navigate to="/admin" replace />} />

      <Route path="/admin" element={<AdminDashboard />}>
        <Route index element={<Navigate to="users" replace />} />
        <Route path="users" element={<UsersComponent />} />
        <Route path="reports" element={<AdminReport />} />
        <Route path="project" element={<ProjectPage />} />
        <Route path="settings" element={<AdminSettings />} />
        <Route path="projects" element={<ProjectPage />} />
        <Route path="timesheet" element={<TimesheetPage />} />
        <Route path="flaggedEntries" element={<FlaggedTimesheetComponent />} />
        <Route path="task" element={<TaskPage />} />
      </Route>
      <Route path="*" element={<h1>404 Not Found </h1>} />
    </Routes>
  );
};

export default AdminRouter;
