import React, { useState, useEffect } from "react";
import axios from "axios";
import { IoClose } from "react-icons/io5";

// Utility function to get the date 7 days ago in YYYY-MM-DD format
const getPastDate = (days) => {
  const date = new Date();
  date.setDate(date.getDate() - days);
  return date.toISOString().split('T')[0];
};

// Utility function to get the current date in YYYY-MM-DD format
const getCurrentDate = () => {
  return new Date().toISOString().split('T')[0];
};

const TimesheetReview = () => {
  const [timesheets, setTimesheets] = useState([]);
  const [filterProject, setFilterProject] = useState("");
  const [filterTask, setFilterTask] = useState("");
  const [filterUser, setFilterUser] = useState(localStorage.getItem("uid"));
  const [startDate, setStartDate] = useState(getPastDate(7)); // Start date 7 days ago
  const [endDate, setEndDate] = useState(getCurrentDate()); // End date as today
  const [projects, setProjects] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [users, setUsers] = useState([]);
  const [error, setError] = useState("");
  const [expandedId, setExpandedId] = useState(null);
  const [flaggedTimesheet, setFlaggedTimesheet] = useState(null);
  const [justification, setJustification] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);

  // Fetch projects
  const fetchProjects = async () => {
    try {
      const response = await axios.get("http://97.107.130.242:14323/api/project/projects");
      setProjects(response.data);
    } catch (error) {
      console.error("Error fetching projects:", error);
    }
  };

  // Fetch tasks
  const fetchTasks = async () => {
    try {
      const response = await axios.get("http://97.107.130.242:14323/api/taskrouter/tasks");
      setTasks(response.data);
    } catch (error) {
      console.error("Error fetching tasks:", error);
    }
  };

  // Fetch users
  const fetchUsers = async () => {
    try {
      const response = await axios.get("http://97.107.130.242:14323/api/user/getUsers");
      setUsers(response.data);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  useEffect(() => {
    fetchProjects();
    fetchTasks();
  }, []);

  // Fetch timesheets based on filters
  const fetchTimesheets = async () => {
    if (!filterProject || !filterTask ) {
      setError("Please fill in all fields.");
      return;
    }

    setIsLoading(true);

    try {
      const response = await axios.get(
        `http://97.107.130.242:14323/api/timesheet/getTimeSheet/${filterUser}?projectId=${filterProject}&task=${filterTask}&startDate=${startDate}&endDate=${endDate}`
      );
      setTimesheets(response.data);
      setError("");
    } catch (error) {
      console.error("Error fetching timesheets:", error);
      setError("Failed to fetch timesheets. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  // Handle justification form submission
  const handleJustify = async () => {
    if (!justification) {
      setError("Justification is required.");
      return;
    }
const lastFlagCommentId = flaggedTimesheet?.flagComment?.length > 0 
  ? flaggedTimesheet.flagComment[flaggedTimesheet.flagComment.length - 1]._id 
  : null;

const url = `http://97.107.130.242:14323/api/timesheet/justify/${lastFlagCommentId}`;
    try {
      await axios.post(
      url,
        { comment: justification }
      );
      setJustification("");
      setFlaggedTimesheet(null);
      setDrawerOpen(false);
      fetchTimesheets(); // Refresh the timesheets list
      setError("");
    } catch (error) {
      console.error("Error justifying timesheet:", error);
      setError("Failed to justify timesheet. Please try again.");
    }
  };

  return (
    <div className="relative">
      <div className="container mx-auto p-4">
        <h1 className="text-3xl font-bold mb-4">Timesheet Logs</h1>

        {/* Filter Section */}
        <div className="bg-white shadow-md p-4 mb-6 rounded">
          <h2 className="text-xl font-semibold mb-2">Filter Timesheets</h2>
          {error && <p className="text-red-500">{error}</p>}
          <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
            <div>
              <label className="block font-medium mb-2">Select Project <sup className="text-red-600">*</sup></label>
              <select
                value={filterProject}
                onChange={(e) => setFilterProject(e.target.value)}
                className="w-full p-2 border rounded"
              >
                <option value="">Select Project</option>
                {projects.map((project) => (
                  <option key={project._id} value={project._id}>
                    {project.projectName}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <label className="block font-medium mb-2">Select Task <sup className="text-red-600">*</sup></label>
              <select
                value={filterTask}
                onChange={(e) => setFilterTask(e.target.value)}
                className="w-full p-2 border rounded"
              >
                <option value="">Select Task</option>
                {tasks.map((task) => (
                  <option key={task._id} value={task.shortForm}>
                    {task.title}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <label className="block font-medium mb-2">Start Date <sup className="text-red-600">*</sup></label>
              <input
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                className="w-full p-2 border rounded"
              />
            </div>

            <div>
              <label className="block font-medium mb-2">End Date <sup className="text-red-600">*</sup></label>
              <input
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                className="w-full p-2 border rounded"
              />
            </div>

            <div className="col-span-1 md:col-span-4 flex justify-end">
              <button
                onClick={fetchTimesheets}
                className="bg-blue-500 text-white px-4 py-2 rounded mt-4"
                disabled={isLoading}
              >
                {isLoading ? 'Loading...' : 'Fetch Timesheets'}
              </button>
            </div>
          </div>
        </div>

        {/* Timesheet List */}
        <div className="grid gap-4">
          {timesheets.length > 0 ? (
            timesheets.map((timesheet) => (
              <div
                key={timesheet._id}
                className={`border rounded shadow-sm mb-4 ${timesheet.isFlagged ? 'bg-red-100' : 'bg-white'}`}
              >
                <div
                  className="p-4 cursor-pointer"
                  onClick={() => {
                    if (timesheet.isFlagged) {
                      setFlaggedTimesheet(timesheet);
                      setDrawerOpen(true);
                    } else {
                      setExpandedId(
                        expandedId === timesheet._id ? null : timesheet._id
                      );
                    }
                  }}
                >
                  <h3 className="text-xl font-semibold mb-2">
                    {timesheet.field_entrytask}
                  </h3>
                  <p className="text-gray-700">
                    <strong>Entry Date:</strong> {timesheet.field_entrydate}
                  </p>
                  <p className="text-gray-700">
                    <strong>Ticket Number:</strong>{" "}
                    {timesheet.field_ticket_number}
                  </p>
                  {/* {expandedId === timesheet._id && !timesheet.isFlagged && ( */}
                    <div className="mt-4">
                      <p className="text-gray-700">
                        <strong>Project Name:</strong> {timesheet.field_proj?.projectName}
                      </p>
                      <p className="text-gray-700">
                        <strong>Description:</strong> {timesheet.body}
                      </p>
                      <p className="text-gray-700">
                        <strong>Time Spent:</strong> {timesheet.field_time_spent}
                      </p>
                    </div>
                  {/* )} */}
                </div>
              </div>
            ))
          ) : (
            <p className="text-gray-600">
              No timesheets found for the selected criteria.
            </p>
          )}
        </div>

        {/* Justification Form */}
       
      </div>

      {/* Drawer for Flagged Comments */}
      {drawerOpen && flaggedTimesheet && (
        <div className="fixed inset-0 flex z-50 right-0 jus">
          <div
            className="fixed inset-0 bg-black bg-opacity-50 right-0"
            onClick={() => setDrawerOpen(false)}
          ></div>
          <div className="bg-white w-[25rem] p-6 shadow-lg overflow-y-auto z-40 right-0 fixed h-full">
            <h2 className="text-xl font-semibold mb-2">Conversations</h2>
            <button
              className="absolute top-6 right-6 text-gray-500"
              onClick={() => setDrawerOpen(false)}
            >
              <IoClose/>
            </button>
            {flaggedTimesheet.flagComment.length > 0 ? (
              <ul className="list-disc pl-5 border rounded-sm">
                {flaggedTimesheet.flagComment.map((comment) => (
                  <li key={comment._id} className="text-gray-700 mb-4">
                    <p className="text-red-400"><strong>Query:</strong> {comment.query}</p>
                    <p><strong>Response:</strong> {comment.response}</p>
                  </li>
                ))}
              </ul>
            ) : (
              <p>No flagged comments available.</p>
            )}
           {!flaggedTimesheet.flagComment[flaggedTimesheet.flagComment.length - 1]?.response&&  <div className="absolute bottom-10">
              <p>
                <strong>Timesheet:</strong> {flaggedTimesheet.field_entrytask}
              </p>
              <textarea
                value={justification}
                onChange={(e) => setJustification(e.target.value)}
                rows="4"
                className="w-full p-2  border rounded mb-4"
                placeholder="Enter your justification here..."
              />
              {error && <p className="text-red-500 mb-4">{error}</p>}
              <button
                onClick={handleJustify}
                className="bg-blue-500 text-white px-4 py-2 rounded"
              >
                Submit Justification
              </button>
           </div>}
          </div>
         
        </div>
        
      )}
    </div>
  );
};

export default TimesheetReview;
