import React, { useEffect, useState } from "react";
import axios from "axios";

const TimesheetForm = () => {
  const currentDate = new Date().toISOString().split('T')[0]; // Get current date in YYYY-MM-DD format
  
  const [timesheets, setTimesheets] = useState([
    {
      title: "",
      uid: localStorage.getItem("uid"), // Default user ID
      field_entrydate: currentDate, // Default to current date
      field_ticket_number: "",
      field_proj: "",
      field_entrytask: "",
      body: "",
      field_time_spent: "",
    },
  ]);

  const [projects, setProjects] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false); // Loading state
  const [successMessage, setSuccessMessage] = useState(""); // Success message
  const [errorMessage, setErrorMessage] = useState(""); // General error message

  // Fetch projects from the API
  const fetchProjects = async () => {
    try {
      const response = await axios.get("http://97.107.130.242:14323/api/project/projects");
      setProjects(response.data);
    } catch (error) {
      console.error("Error fetching projects:", error);
    }
  };

  // Fetch tasks from the API
  const fetchTasks = async () => {
    try {
      const response = await axios.get("http://97.107.130.242:14323/api/taskrouter/tasks");
      setTasks(response.data);
    } catch (error) {
      console.error("Error fetching tasks:", error);
    }
  };

  useEffect(() => {
    fetchProjects();
    fetchTasks();
  }, []);

  // Handle input change for individual timesheet entries
  const handleInputChange = (index, e) => {
    const { name, value } = e.target;
    const updatedTimesheets = [...timesheets];
    updatedTimesheets[index] = { ...updatedTimesheets[index], [name]: value };
    setTimesheets(updatedTimesheets);
  };

  // Handle project selection change
  const handleProjectChange = (index, value) => {
    const updatedTimesheets = [...timesheets];
    updatedTimesheets[index] = { ...updatedTimesheets[index], field_proj: value };
    setTimesheets(updatedTimesheets);
  };

  // Handle task selection change
  const handleTaskChange = (index, value) => {
    const updatedTimesheets = [...timesheets];
    updatedTimesheets[index] = { ...updatedTimesheets[index], field_entrytask: value };
    setTimesheets(updatedTimesheets);
  };

  // Add a new blank timesheet entry
  const handleAddTimesheet = () => {
    setTimesheets([
      ...timesheets,
      {
        title: "",
        uid: localStorage.getItem("uid"), // Default user ID
        field_entrydate: currentDate, // Default to current date
        field_ticket_number: "",
        field_proj: "",
        field_entrytask: "",
        body: "",
        field_time_spent: "",
      },
    ]);
  };

  // Remove a timesheet entry by index
  const handleRemoveTimesheet = (index) => {
    const updatedTimesheets = timesheets.filter((_, i) => i !== index);
    setTimesheets(updatedTimesheets);
  };

  // Validate timesheet form entries
  const validateForm = () => {
    const errors = timesheets.map((timesheet) => {
      const error = {};
      if (!timesheet.field_proj) error.field_proj = "Project is required";
      if (!timesheet.field_entrytask) error.field_entrytask = "Task is required";
      if (!timesheet.field_entrydate) error.field_entrydate = "Entry date is required";
      if (!timesheet.field_ticket_number) error.field_ticket_number = "Ticket number is required";
      if (!timesheet.field_time_spent) error.field_time_spent = "Time spent is required";
      if (!timesheet.body) error.body = "Description is required";
      return error;
    });

    setErrors(errors);
    return errors.every((error) => Object.keys(error).length === 0);
  };

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrorMessage("");
    setSuccessMessage("");

    if (!validateForm()) {
      setErrorMessage("Please fix the errors before submitting.");
      return;
    }

    setLoading(true);
    try {
      const response = await axios.post("http://97.107.130.242:14323/api/timesheet/massInsert", timesheets);
      setLoading(false);
      setSuccessMessage("Timesheets submitted successfully!");
      setErrorMessage("");
      // Reset form after successful submission
      setTimesheets([
        {
          title: "",
          uid: localStorage.getItem("uid"), // Default user ID
          field_entrydate: currentDate, // Default to current date
          field_ticket_number: "",
          field_proj: "",
          field_entrytask: "",
          body: "",
          field_time_spent: "",
        },
      ]);
    } catch (error) {
      setLoading(false);
      setErrorMessage("Something went wrong. Please try again.");
      console.error("Error submitting timesheets:", error);
    }
  };

  return (
    <div className="mx-auto p-4 sm:p-6 lg:p-8 max-w-4xl">
      <h1 className="text-2xl font-semibold mb-6">Timesheet Entry</h1>
      {successMessage && <p className="text-green-600 mb-4">{successMessage}</p>}
      {errorMessage && <p className="text-red-600 mb-4">{errorMessage}</p>}
      
      <form onSubmit={handleSubmit} className="space-y-6 border p-4 sm:p-6 lg:p-8 rounded-xl bg-gray-50 shadow-lg">
        {timesheets.map((timesheet, index) => (
          <div key={index} className="space-y-4 border-b pb-4">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              {/* Project Name */}
              <div>
                <label className="block text-gray-700 font-semibold">Project Name <sup className="text-red-600">*</sup></label>
                <select
                  className="w-full p-2 border border-gray-300 rounded mt-1"
                  value={timesheet.field_proj}
                  onChange={(e) => handleProjectChange(index, e.target.value)}
                >
                  <option value="">Select Project</option>
                  {projects.map((project) => (
                    <option key={project._id} value={project._id}>
                      {project.projectName}
                    </option>
                  ))}
                </select>
                {errors[index]?.field_proj && <p className="text-red-500">{errors[index].field_proj}</p>}
              </div>

              {/* Task Name */}
              <div>
                <label className="block text-gray-700 font-semibold">Task Name <sup className="text-red-600">*</sup></label>
                <select
                  className="w-full p-2 border border-gray-300 rounded mt-1"
                  value={timesheet.field_entrytask}
                  onChange={(e) => handleTaskChange(index, e.target.value)}
                >
                  <option value="">Select Task</option>
                  {tasks.map((task) => (
                    <option key={task._id} value={task.shortForm}>
                      {task.title}
                    </option>
                  ))}
                </select>
                {errors[index]?.field_entrytask && <p className="text-red-500">{errors[index].field_entrytask}</p>}
              </div>

              {/* Entry Date */}
              <div>
                <label className="block text-gray-700 font-semibold">Entry Date <sup className="text-red-600">*</sup></label>
                <input
                  type="date"
                  name="field_entrydate"
                  value={timesheet.field_entrydate}
                  onChange={(e) => handleInputChange(index, e)}
                  className="w-full p-2 border border-gray-300 rounded mt-1"
                  required
                />
                {errors[index]?.field_entrydate && <p className="text-red-500">{errors[index].field_entrydate}</p>}
              </div>

              {/* Ticket Number */}
              <div>
                <label className="block text-gray-700 font-semibold">Ticket Number <sup className="text-red-600">*</sup></label>
                <input
                  type="text"
                  name="field_ticket_number"
                  placeholder="NA"
                  value={timesheet.field_ticket_number}
                  onChange={(e) => handleInputChange(index, e)}
                  className="w-full p-2 border border-gray-300 rounded mt-1"
                  required
                />
                {errors[index]?.field_ticket_number && <p className="text-red-500">{errors[index].field_ticket_number}</p>}
              </div>

              {/* Time Spent */}
              <div>
                <label className="block text-gray-700 font-semibold">Time Spent <sup className="text-red-600">*</sup></label>
                <input
                  type="number"
                  min={0}
                  name="field_time_spent"
                  value={timesheet.field_time_spent}
                  onChange={(e) => handleInputChange(index, e)}
                  className="w-full p-2 border border-gray-300 rounded mt-1"
                  required
                  placeholder="e.g., 5 hours"
                />
                {errors[index]?.field_time_spent && <p className="text-red-500">{errors[index].field_time_spent}</p>}
              </div>
            </div>
            {/* Details */}
            <div>
              <label className="block text-gray-700 font-semibold">Details <sup className="text-red-600">*</sup></label>
              <textarea
                name="body"
                value={timesheet.body}
                onChange={(e) => handleInputChange(index, e)}
                className="w-full p-2 border border-gray-300 rounded mt-1"
                required
              />
              {errors[index]?.body && <p className="text-red-500">{errors[index].body}</p>}
            </div>

            <button
              type="button"
              onClick={() => handleRemoveTimesheet(index)}
              className="bg-red-500 text-white py-2 px-4 rounded mt-2"
            >
              Remove Entry
            </button>
          </div>
        ))}

        <div className="flex justify-between items-center mt-4">
          <button
            type="button"
            onClick={handleAddTimesheet}
            className="bg-green-500 text-white py-2 px-4 rounded"
          >
            Add More
          </button>
          <button
            type="submit"
            className={`bg-blue-500 text-white py-2 px-4 rounded ${loading ? "opacity-50 cursor-not-allowed" : ""}`}
            disabled={loading}
          >
            {loading ? "Submitting..." : "Submit Timesheets"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default TimesheetForm;
