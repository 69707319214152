import React, { useContext, useEffect } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { FaClock, FaRegUser, FaTasks } from "react-icons/fa";
import { IoExit, IoFlagSharp, IoSettings } from "react-icons/io5";
import { SiOpenproject } from "react-icons/si";
import { GrProjects } from "react-icons/gr";
import { HiOutlineDocumentReport } from "react-icons/hi";
import { useAuth } from "./AuthContext/AuthContext";

function AdminDashboard() {
const naviagte=useNavigate()
  // useEffect(() => {
  //   naviagte("/admin/users")
  // })
  const { user,logout } = useAuth();
  return (
    <div className="w-full h-screen overflow-y-hidden  scrollbar-hide">
      <div className="flex ">
        <div className=" w-[6rem] h-[100vh] flex bg-slate-900  shadow-4xl  flex-col">
          <p className="self-center text-lg font-extrabold pb-10 text-white mt-10 capitalize">
            ADMIN
          </p>
          <ul className="flex flex-col text gap-2 text-white justify-center items-center">
            <Link
              className="text-xl cursor-pointer hover:bg-blue-800 hover:rounded-full p-3 w-[3rem] h-[3rem] items-center justify-center flex rounded-md"
              to={"/admin/users"}
            >
              <FaRegUser />
            </Link>
            <Link
              className=" text-xl cursor-pointer hover:bg-blue-800 hover:rounded-full p-3 w-[3rem] h-[3rem] items-center justify-center flex rounded-md"
              to={"/admin/projects"}
            >
              <GrProjects />
                      </Link>
                      <Link
              className=" text-xl cursor-pointer hover:bg-blue-800 hover:rounded-full p-3 w-[3rem] h-[3rem] items-center justify-center flex rounded-md"
              to={"/admin/task"}
            >
              <FaTasks />
            </Link>
             <Link
              className="text-xl cursor-pointer hover:bg-blue-800 hover:rounded-full p-3 w-[3rem] h-[3rem] items-center justify-center flex rounded-md"
              to={"/admin/timesheet"}
            >
              <FaClock />
            </Link>
            <Link
              className=" text-3xl cursor-pointer hover:bg-blue-800 hover:rounded-full p-3 w-[3rem] h-[3rem] items-center justify-center flex rounded-md"
              to={"/admin/reports"}
            >
              <HiOutlineDocumentReport />
            </Link>
            <Link
              className="text-xl cursor-pointer hover:bg-blue-800 hover:rounded-full p-3 w-[3rem] h-[3rem] items-center justify-center flex rounded-md"
              to={"/admin/flaggedEntries"}
            >
              <IoFlagSharp />
            </Link>

           

            <Link
              className="text-xl cursor-pointer hover:bg-blue-800 hover:rounded-full p-3 w-[3rem] h-[3rem] items-center justify-center flex rounded-md"
              to={"/admin/settings"}
            >
              <IoSettings />
            </Link>
            <Link
              onClick={() => {
                logout()
                localStorage.clear()
              }}
              className="text-xl cursor-pointer hover:bg-blue-800 hover:rounded-full p-3 w-[3rem] h-[3rem] items-center justify-center flex rounded-md"
              to={"#"}
            >
              <IoExit />
            </Link>
          </ul>
        </div>
        <div className="w-full h-[90vh]  m-12 rounded-lg overflow-y-scroll">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default AdminDashboard;
