import React, { useState, useEffect } from "react";
import axios from "axios";
import { IoFlag, IoFlagOutline } from "react-icons/io5";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const TimesheetPage = () => {
  const [timesheets, setTimesheets] = useState([]);
  const [filterUser, setFilterUser] = useState("");
  const [filterProject, setFilterProject] = useState("");
  const [filterTask, setFilterTasks] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [reason, setReason] = useState("");
  const [currentId, setCurrentId] = useState(null);
  const [users, setUsers] = useState([]);
  const [projects, setProjects] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [showFlagForm, setShowFlagForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    // Calculate default dates
    const today = new Date();
    const weekAgo = new Date();
    weekAgo.setDate(today.getDate() - 7);

    setEndDate(today.toISOString().split('T')[0]);
    setStartDate(weekAgo.toISOString().split('T')[0]);

    // Fetch users, projects, and tasks
    fetchUsers();
    fetchProjects();
    fetchTasks();
  }, []);

  useEffect(() => {
    if (error) {
      toast.error(error); // Using toast.error for better styling
      setError("");
    }
  }, [error]);

  const fetchUsers = async () => {
    try {
      const response = await axios.get(
        "http://97.107.130.242:14323/api/user/getUsers"
      );
      setUsers(response.data);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const fetchProjects = async () => {
    try {
      const response = await axios.get(
        "http://97.107.130.242:14323/api/project/projects"
      );
      setProjects(response.data);
    } catch (error) {
      console.error("Error fetching projects:", error);
    }
  };

  const fetchTasks = async () => {
    try {
      const response = await axios.get(
        "http://97.107.130.242:14323/api/taskrouter/tasks"
      );
      setTasks(response.data);
    } catch (error) {
      console.error("Error fetching tasks:", error);
    }
  };

  const flagTimesheet = async (_id, status, comment) => {
    try {
      const response = await axios.post(
        "http://97.107.130.242:14323/api/timesheet/flagTimeSheet",
        { _id, status, comment }
      );
      if (response.status === 200) {
        setShowFlagForm(false);
        handleFilter();
      }
    } catch (error) {
      console.error("Error flagging timesheet:", error);
    }
  };

  const handleFilter = async () => {
    setError("");
console.log("Filter User",filterUser)
    if (!filterUser || filterUser === "") {
      setError("Please select user.");
      return;
    }

    if (!startDate || !endDate) {
      setError("Start date and end date are required.");
      return;
    }

    if (new Date(startDate) > new Date(endDate)) {
      setError("Start date cannot be later than end date.");
      return;
    }

    setLoading(true);

    try {
      const response = await axios.get(
        `http://97.107.130.242:14323/api/timesheet/getTimeSheet/${filterUser}?startDate=${startDate}&endDate=${endDate}&projectId=${filterProject}&task=${filterTask}`
      );
      setTimesheets(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error applying filter:", error);
      setError(error?.response?.data?.error || "Failed to apply filter. Please try again.");
      setLoading(false);
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-3xl font-bold mb-4">Admin Timesheet Management</h1>

      {/* Filter Section */}
      <div className="bg-white shadow-md p-4 mb-6 rounded">
        <h2 className="text-xl font-semibold mb-2">Filter Timesheets</h2>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div>
            <label className="block font-medium mb-2">Filter by User<sup className="text-red-600">*</sup></label>
            <select
              value={filterUser}
              onChange={(e) => setFilterUser(e.target.value)}
              className="w-full p-2 border rounded"
            >
              <option value="">Select User</option>
              {users.map((user) => (
                <option key={user._id} value={user._id}>
                  {user.name}
                </option>
              ))}
            </select>
          </div>

          <div>
            <label className="block font-medium mb-2">Filter by Project</label>
            <select
              value={filterProject}
              onChange={(e) => setFilterProject(e.target.value)}
              className="w-full p-2 border rounded"
            >
              <option value="">All Projects</option>
              {projects.map((project) => (
                <option key={project._id} value={project._id}>
                  {project.projectName}
                </option>
              ))}
            </select>
          </div>

          <div>
            <label className="block font-medium mb-2">Filter by Task</label>
            <select
              value={filterTask}
              onChange={(e) => setFilterTasks(e.target.value)}
              className="w-full p-2 border rounded"
            >
              <option value="">All Tasks</option>
              {tasks.map((task) => (
                <option key={task._id} value={task.shortForm}>
                  {task.title}
                </option>
              ))}
            </select>
          </div>

          <div>
            <label className="block font-medium mb-2">Start Date <sup className="text-red-600">*</sup></label>
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              className="w-full p-2 border rounded"
            />
          </div>

          <div>
            <label className="block font-medium mb-2">End Date <sup className="text-red-600">*</sup></label>
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              className="w-full p-2 border rounded"
            />
          </div>

          <div className="col-span-1 md:col-span-3 flex justify-end">
            <button
              onClick={handleFilter}
              className="bg-blue-500 text-white px-4 py-2 rounded mt-4"
            >
              Apply Filters
            </button>
          </div>
        </div>
      </div>

      {/* Loading state */}
      {loading && <p className="text-gray-500">Loading timesheets...</p>}

      {/* Timesheet List */}
      <div className="grid gap-4">
        {!loading && timesheets.length > 0 ? (
          timesheets.map((timesheet) => (
            <div
              key={timesheet._id}
              className={`p-4 border rounded shadow-sm  relative ${
                timesheet?.isFlagged ? "bg-red-50" : "bg-white"
              } ${timesheet?.flagComment?.length>0 ? "bg-orange-50" : ""}`}
            >
              <div
                onClick={() => {
                  setCurrentId(timesheet._id);
                  setShowFlagForm(true);
                }}
                className={`text-2xl absolute right-10 h-[3rem] w-[3rem] cursor-pointer rounded-full p-2 flex justify-center items-center shadow-lg`}
              >
                {timesheet?.isFlagged ? <IoFlag /> : <IoFlagOutline />}
              </div>
              <h3 className="text-xl font-semibold mb-2">{timesheet.title}</h3>
              <p className="text-gray-700">
                <strong>Entry Date:</strong> {timesheet.field_entrydate}
              </p>
              <p className="text-gray-700">
                <strong>Ticket Number:</strong> {timesheet.field_ticket_number}
              </p>
              <p className="text-gray-700">
                <strong>Project:</strong> {timesheet.field_proj?.projectName}
              </p>
              <p className="text-gray-700">
                <strong>Task:</strong> {timesheet.field_entrytask}
              </p>
              <p className="text-gray-700">
                <strong>Description:</strong> {timesheet.body}
              </p>
              <p className="text-gray-700">
                <strong>Time Spent:</strong> {timesheet.field_time_spent}
              </p>

            </div>
          ))
        ) : (
          <p className="text-gray-600">
            No timesheets found for the selected filters.
          </p>
        )}
      </div>

      {showFlagForm && (
        <div className="fixed w-full h-screen bg-gray-800/80 left-0 right-0 top-0 flex justify-center items-center shadow-inner bottom-0">
          <div className="bg-white w-1/2 rounded-xl p-8 flex gap-2 flex-col">
            <input
              type="text"
              className="p-2"
              placeholder="Enter reason for flag"
              onChange={(e) => setReason(e.target.value)}
              value={reason}
            />
            <button onClick={() => flagTimesheet(currentId, true, reason)}>
              Submit
            </button>
          </div>
        </div>
      )}

      <ToastContainer />
    </div>
  );
};

export default TimesheetPage;
