import React, { useState } from "react";
import axios from "axios"; // For making HTTP requests
import { IoClose } from "react-icons/io5";

// Enum for Roles (should match the values in your backend)
const roles = [
  "IT",
  "Human Resource",
  "DevOps",
  "QA",
  "Admin",
  "Operations",
  "Accounts",
];

// Enum for Employment Status (should match the values in your backend)
const employmentStatuses = [
  "freelancer",
  "fulltime",
  "permanent",
  "contract",
  "partime",
  "consultant",
  "other",
];

const CreateUserForm = ({ handleClose, refetch }) => {
  // State for form inputs
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    role: "",
    projects: [], // This could be an array of ObjectIds, for simplicity here it will be a string
    employeeId: "",
    employmentStatus: "",
    isActive: true,
  });

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Send the data to your backend API
      const response = await axios.post(
        "http://97.107.130.242:14323/api/user/createUser",
        formData
      ); // Adjust your endpoint URL
      console.log("User created successfully:", response.data);
      if (response.status === 200 || response.status === 201) {
        handleClose(false);
        refetch();
      }
    } catch (error) {
      console.error("Error creating user:", error);
    }
  };

  return (
    <div className="max-w-3xl mx-auto p-8 relative">
      <button
        type="button"
        className="absolute right-5"
        onClick={() => handleClose(false)}
      >
        <IoClose className="text-2xl text-[#333333]" />
      </button>
      <h1 className="text-2xl font-semibold mb-6   text-[#333333]">
        Add New User
      </h1>
      <form onSubmit={handleSubmit} className="space-y-6">
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Name:
          </label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            required
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-indigo-500 focus:border-indigo-500"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Email:
          </label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            required
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-indigo-500 focus:border-indigo-500"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Role:
          </label>
          <select
            name="role"
            value={formData.role}
            onChange={handleInputChange}
            required
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-indigo-500 focus:border-indigo-500"
          >
            <option value="">Select Role</option>
            {roles.map((role) => (
              <option key={role} value={role}>
                {role}
              </option>
            ))}
          </select>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Employee ID:
          </label>
          <input
            type="text"
            name="employeeId"
            value={formData.employeeId}
            onChange={handleInputChange}
            required
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-indigo-500 focus:border-indigo-500"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Employment Status:
          </label>
          <select
            name="employmentStatus"
            value={formData.employmentStatus}
            onChange={handleInputChange}
            required
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-indigo-500 focus:border-indigo-500"
          >
            <option value="">Select Status</option>
            {employmentStatuses.map((status) => (
              <option key={status} value={status}>
                {status}
              </option>
            ))}
          </select>
        </div>

        <div className="flex items-center">
          <input
            type="checkbox"
            name="isActive"
            checked={formData.isActive}
            onChange={() =>
              setFormData({ ...formData, isActive: !formData.isActive })
            }
            className="h-4 w-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
          />
          <label className="ml-2 block text-sm text-gray-900">Is Active</label>
        </div>

        <button
          type="submit"
          className="w-full bg-indigo-600 text-white font-semibold py-2 px-4 rounded-md shadow hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Create User
        </button>
      </form>
    </div>
  );
};

export default CreateUserForm;
