import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import UserDashboard from '../../components/UserDashboard';
import UserTimesheetPage from '../../components/UserTimesheetPage';
import TimesheetReview from '../../components/TimesheetSection/TimesheetReview';
import UserNotification from '../../components/UserSection/UserNotification';
import UserSettings from '../../components/UserSection/UserSettings';
import UserReport from '../../components/UserSection/UserReport';

const UserRouter = () => {
  return (
    <Routes>
        <Route index element={<Navigate to="/user" replace />} />

      <Route path="/user" element={<UserDashboard />}>
        <Route index element={<Navigate to="user-timesheet" replace />} />
        <Route path="user-timesheet" element={<UserTimesheetPage />} />
        <Route path="user-timesheet/review" element={<TimesheetReview />} />
        <Route path="notification" element={<UserNotification />} />
        <Route path="settings" element={<UserSettings />} />
        <Route path="reports" element={<UserReport />} />
      </Route>
      <Route path="*" element={<h1>404 Not Found</h1>} />
    </Routes>
  );
};

export default UserRouter;
