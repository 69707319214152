import React, { useEffect, useState } from "react";
import ProjectList from "./ProjectSection/ProjectList";
import { IoAdd } from "react-icons/io5";
import CreateProjectForm from "./ProjectSection/ProjectForm";
import axios from "axios";

function ProjectPage() {
  const [showForm, setShowForm] = useState(false);
  const [users, setUsers] = useState([]);
  const [projects, setProjects] = useState([]);

  const fetchProjects = async () => {
    try {
      const response = await axios.get(
        "http://97.107.130.242:14323/api/project/projects"
      ); // Replace with your actual API endpoint
      setProjects(response.data);
    } catch (error) {
      console.error("Error fetching projects:", error);
    }
  };

  useEffect(() => {
    fetchProjects();
  }, []);
  return (
    <div>
      <ProjectList projects={projects} />
      <button
        onClick={() => setShowForm(true)}
        className="text-white font-semibold w-[3rem] h-[3rem] absolute right-20 bottom-20 bg-red-500 rounded-2xl flex justify-center items-center text-2xl"
      >
        <IoAdd />
      </button>
      {showForm && (
        <div
          className="fixed w-full h-screen bg-gray-800/80  left-0 right-0 top-0 flex justify-center items-center shadow-inner 
        bottom-0"
        >
          <div className="bg-white w-1/2 rounded-xl p-8">
            <CreateProjectForm
              handleClose={setShowForm}
              refetch={fetchProjects}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default ProjectPage;
