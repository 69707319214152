import axios from 'axios';
import React, { useEffect, useState } from 'react';

function UserNotification() {
  const [notifications, setNotifications] = useState([]);
  const [filter, setFilter] = useState('all');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    getNotifications();
  }, []);

  const getNotifications = async () => {
    setLoading(true);
    try {
      const response = await axios.get("http://97.107.130.242:14323/api/notification/notifications/66dad25f146f62ad9431fe37");
      setNotifications(response.data);
    } catch (error) {
      setError("Failed to fetch notifications");
    } finally {
      setLoading(false);
    }
  };

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const handleMarkAsRead = async (notificationId) => {
    try {
      await axios.put(`http://97.107.130.242:14323/api/notification/read/${notificationId}`);
      setNotifications(notifications.map(notification => 
        notification._id === notificationId 
          ? { ...notification, status: 'read' } 
          : notification
      ));
    } catch (error) {
      console.error("Error marking notification as read", error);
    }
  };

  const filteredNotifications = notifications.filter(notification => {
    if (filter === 'all') return true;
    return notification.status === filter;
  });

  return (
    <div className="p-8 max-w-lg mx-auto">
      <h1 className="text-2xl font-semibold mb-4">User Notifications</h1>
      
      <div className="mb-6">
        <label className="block mb-2 text-sm font-medium text-gray-700">Filter</label>
        <select
          className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
          value={filter}
          onChange={handleFilterChange}
        >
          <option value="all">All</option>
          <option value="unread">Unread</option>
          <option value="read">Read</option>
        </select>
      </div>

      {loading ? (
        <div className="flex justify-center my-10">
          <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12"></div>
        </div>
      ) : error ? (
        <p className="text-red-500">{error}</p>
      ) : (
        <ul>
          {filteredNotifications.length > 0 ? (
            filteredNotifications.map(notification => (
              <li key={notification._id} className="mb-4 p-4 border border-gray-200 rounded-md bg-white shadow-sm">
                <h4 className="text-lg font-medium">{notification.title}</h4>
                <p className="text-sm text-gray-600">{notification.message}</p>
                <small className="text-xs text-gray-400">{new Date(notification.createdAt).toLocaleString()}</small>
                {notification.status === 'unread' && (
                  <button
                    className="ms-10 mt-2 px-2 py-1 bg-indigo-600 text-white text-xs rounded-md hover:bg-indigo-700 focus:outline-none"
                    onClick={() => handleMarkAsRead(notification._id)}
                  >
                    Mark as Read
                  </button>
                )}
              </li>
            ))
          ) : (
            <p>No notifications available.</p>
          )}
        </ul>
      )}
    </div>
  );
}

export default UserNotification;
