import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { IoAdd, IoClose } from 'react-icons/io5';
import { Button } from '@mui/material';

const TaskComponent = () => {
  const [tasks, setTasks] = useState([]);
  const [title, setTitle] = useState('');
  const [shortForm, setShortForm] = useState('');
  const [showForm, setShowForm] = useState(false);
  const [description, setDescription] = useState('');
  const [category, setCategory] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  // Categories for the task
  const categories = [
    'development',
    'design',
    'documentation',
    'testing',
    'management',
    'meeting',
    'other'
  ];

  // Fetch all tasks
  const fetchAllTasks = async () => {
    try {
      const response = await axios.get('http://97.107.130.242:14323/api/taskrouter/tasks');
      setTasks(response.data);
    } catch (err) {
      console.error('Error fetching tasks:', err);
    }
  };

  // Handle form submission for creating a task
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!title || !shortForm || !description || !category) {
      setError('Please fill in all fields.');
      return;
    }

    try {
      const response = await axios.post('http://97.107.130.242:14323/api/taskrouter/tasks', {
        title,
        shortForm,
        description,
        category,
      });
      setSuccess('Task created successfully!');
      setError('');
      // Fetch all tasks again to include the newly created task
      fetchAllTasks();
      // Clear form fields
      setTitle('');
      setShortForm('');
      setDescription('');
      setCategory('');
    } catch (err) {
      setError('Failed to create task. Please try again.');
      setSuccess('');
    }
  };

  useEffect(() => {
    fetchAllTasks();
  }, []);

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-3xl font-bold mb-4">Task Management</h1>

      {/* Task List */}
      <div className="bg-white shadow-md p-4 mb-6 rounded">
        <h2 className="text-xl font-semibold mb-2">Task List</h2>
        {tasks.length === 0 ? (
          <p>No tasks available.</p>
        ) : (
          <ul className="space-y-4">
            {tasks.map((task) => (
              <li key={task._id} className="p-4 border border-gray-300 rounded">
                <h3 className="text-xl font-semibold">{task.title}</h3>
                <p><strong>Short Form:</strong> {task.shortForm}</p>
                <p><strong>Description:</strong> {task.description}</p>
                <p><strong>Category:</strong> {task.category}</p>
                <p><strong>Created At:</strong> {new Date(task.createdAt).toLocaleDateString()}</p>
              </li>
            ))}
          </ul>
        )}
      </div>

      <button onClick={()=>setShowForm(true)}><IoAdd className='text-2xl font-bold bg-red-400 rounded-lg p-1 w-[2rem] h-[2rem] fixed right-20 bottom-20'/></button>

      {/* Create Task Form */}
    {showForm&&  <div className="bg-gray-800/80 fixed left-0 right-0 top-0 bottom-0 flex justify-center items-center">
      <div className="bg-white w-1/2 shadow-md p-8 rounded">
          <div className='flex justify-between items-center'>
            <h2 className="text-xl font-semibold mb-2">Create New Task</h2>
            <IoClose className='text-2xl' onClick={()=>setShowForm(false)}/>
        </div>
        {error && <p className="text-red-500">{error}</p>}
        {success && <p className="text-green-500">{success}</p>}
        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label className="block font-medium mb-2">Title</label>
              <input
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                className="w-full p-2 border rounded"
              />
            </div>

            <div>
              <label className="block font-medium mb-2">Short Form</label>
              <input
                type="text"
                value={shortForm}
                onChange={(e) => setShortForm(e.target.value)}
                className="w-full p-2 border rounded"
              />
            </div>

            <div className="col-span-2">
              <label className="block font-medium mb-2">Description</label>
              <textarea
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                className="w-full p-2 border rounded"
                rows={4}
              />
            </div>

            <div>
              <label className="block font-medium mb-2">Category</label>
              <select
                value={category}
                onChange={(e) => setCategory(e.target.value)}
                className="w-full p-2 border rounded"
              >
                <option value="">Select Category</option>
                {categories.map((cat) => (
                  <option key={cat} value={cat}>
                    {cat.charAt(0).toUpperCase() + cat.slice(1)}
                  </option>
                ))}
              </select>
            </div>

            <div className="col-span-2 flex justify-end">
              <button
                type="submit"
                className="bg-blue-500 text-white px-4 py-2 rounded"
              >
                Create Task
              </button>
            </div>
          </div>
        </form>
      </div></div>}
    </div>
  );
};

export default TaskComponent;