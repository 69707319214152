import React, { useEffect, useState } from 'react';
import "./App.css"
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { AuthProvider, useAuth } from './components/AuthContext/AuthContext';
import Login from './components/LoginSectiom/Login';
import HomeComponent from './components/HomeComponent';
import AdminRouter from './components/router/AdminRouter';
import UserRouter from './components/router/UserRouter';
import ForgotPassword from './components/ForgotPassword/ForgotPassword';
const App = () => {
    const roles = localStorage.getItem('token');
  
  return (
   <AuthProvider>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/reset" element={<ForgotPassword />} />
          <Route path="*" element={<AuthBasedRouter />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
};

// Determine which routes to render based on user role

const AuthBasedRouter = () => {
  const [isAdmin, setIsAdmin] = useState(null);

  useEffect(() => {
    // Check local storage for roles
    const roles = localStorage.getItem('roles');
    if (roles) {
      const parsedRoles = JSON.parse(roles);
      setIsAdmin(parsedRoles.includes('ADMIN'));
    } else {
      setIsAdmin(false);
    }
  }, []);

  // If still determining auth state, show a loading state
  if (isAdmin === null) {
    return <div>Loading...</div>;
  }

  // Redirect to login if no token is present
  const token = localStorage.getItem('token');
  if (!token) {
    return <Navigate to="/login" replace />;
  }

  // Redirect based on role
  return isAdmin ? <AdminRouter /> : <UserRouter />;
};



// export const AuthBasedRouter = () => {
 

//   if (isAdmin === null) {
//     // Show a loading state or placeholder while checking roles
//     return <div>Loading...</div>;
//   }

//   if (isAdmin) {
//     // If admin, show AdminRouter
//     return <AdminRouter />;
//   } else {
//     // If not admin, show UserRouter
//     return <UserRouter />;
//   }
// };



export default App;
